body {
  background-color: black;
}
.border-gray {
  border-color: #ddd;
}

.App{
  background-color: black;
  color: white;
}

.logo{
  width: 100px;
  height:auto;
}

.btn-pink {
  color: #fff !important;
  background-color: #EC1B78 !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
}

.inline-select{
  position: absolute !important;
  top: 50%;
  right: 0.1rem !important;
  transform: translateY(-50%);
  border: none;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: inherit;
  color: inherit;
  font-size: 13px;
  width: 10%;
}
.inline-select:hover{
  outline: none;
  border: none;
}
.inline-select:active{
  outline: none;
  border: none;
}
.inline-select:focus{
  outline: none;
  border: none;
}
.inline-select option{
  background-color: black !important;
  color: white !important;
}

.inline-select option:hover{
  outline: none !important;
  border: none !important;
}

.inline-select option:active{
  outline: none !important;
  border: none !important;
}

.bg-dark{
  background-color: black !important;
}
.copy-alert{
  background: black !important;
  color: white !important;
  margin: 10px auto !important;
  padding: 0 !important;
  text-align: center !important;
}
.left-white{
  border-left: 1px solid white !important;
}

.btn-black{
  background-color: black !important;
  color: #EC1B78 !important;
  border: 1px solid #EC1B78 !important;
  border-radius: 10px !important;
}

.form-control::placeholder {
  color: white !important;
}

.login .form-label{
  color: black !important;
}
.form-label{
  color: white !important;
}


.history-item {
  cursor: pointer;
}

.history-item span {
  font-size: 14px;
}

.summary-box span {
  font-size: 14px;
}

.summary-box{
  color: white;
  font-weight: 800;
}
.summary-box h4{
  color: white;
  font-weight: 800;
}
.tile-box-0{
  background-color: #00A7CD !important;
}
.tile-box-1{
  background-color: #EC1B78 !important;
}
.tile-box-2{
  background-color: #00A7CD !important;
}
.tile-box-3{
  background-color: #EC1B78 !important;
}

.history-page-utility > *:not(:last-child) {
  margin-right: 10px;
}

.pagination-button {
  padding: 5px;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #00A7CD;
}

.btn-primary:hover {
  color: #fff;
  background-color: #00A7CD;
}

.btn-outline-primary {
  color: white;
  border-color: white;
}

.btn-outline-primary:hover {
  color: #00A7CD;
  border-color: #00A7CD;
}

.input-group > .form-control:focus {
  transition: all 0.2s linear;
  border-color: #00A7CD;
  outline: 0;
  box-shadow: inset 0 0 0 1px #00A7CD;
}

.form-outline .form-control:focus ~ .form-notch .form-notch-leading,
.form-outline .form-control:focus ~ .form-notch .form-notch-middle,
.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-color: #00A7CD;
}

.form-outline .form-control:focus ~ .form-label {
  color: #00A7CD;
}

.form-control::placeholder {
  color: black !important; /* Replace with the desired color */
}